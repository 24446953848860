export default function() {
	return {
		permissions: null,
		user: null,
		notifications: [],
		notificationStart: false,
		totalNotifications: 0,
		actionUrl: false,
		ratingCriteriaId: null,
		criteriaContest: null
	}
}
