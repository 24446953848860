import auth from '@/middleware/auth'

export default [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'dashboard',
		beforeEnter: auth,
		component: () => import('@/pages/Dashboard')
	},
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'Roles'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'Users'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/dealers/:page?/:id?',
		name: 'Dealers',
		icon: 'countries',
		meta: {
			title: 'Countries'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Dealers')
	},
	{
		path: '/categories/:page?/:id?',
		name: 'Categories',
		icon: 'criteria',
		meta: {
			title: 'Criteria'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Categories')
	},
	{
		path: '/ratings/:page?/:id?',
		name: 'Ratings',
		icon: 'rating',
		meta: {
			title: 'Evaluations'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Ratings')
	},
	{
		path: '/contests/:id?',
		name: 'Contests',
		icon: 'contest',
		meta: {
			title: 'Disputes'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Contests')
	},
	{
		path: '/ranking/:id?',
		name: 'Ranking',
		icon: 'ranking',
		meta: {
			title: 'Ranking'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Ranking')
	}
]
