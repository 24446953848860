import Api from '@/services/api'
import Pusher from 'pusher-js'

export function getUserData(context) {
	Api.get('user/me').then(({ data }) => {
		context.commit('setPermissions', JSON.parse(data.permission.route))
		context.commit('setUser', data)
	})
}

export function deleteUserData(context) {
	localStorage.removeItem('@stup:token')

	context.commit('setPermissions', null)
	context.commit('setUser', null)
}

export function getNotifications(context) {
	Api.get('notification/findAll').then(({ data }) => {
		context.commit('setNotifications', data)
		context.commit('setTotalNotifications', data.map((n) => n.pivot.read == 0).length)
	})
}

export function subscribeNotification({ commit, dispatch, getters }) {
	if (!getters.notificationStart) {
		const pusher = new Pusher('dcd410da214b34f830bc', {
			cluster: 'us2'
		})

		const channel = pusher.subscribe('notification')

		channel.bind('notification', () => {
			dispatch('getNotifications')
		})

		channel.bind('contest', (data) => {
			if (data.rating_criteria_id) {
				commit('setRatingCriteriaId', data.rating_criteria_id)
			}
		})

		pusher.connection.bind('state_change', (states) => {
			if (states.current == 'connected') {
				dispatch('getNotifications')
				commit('setNotificationStart', true)
			}
		})
	}
}
